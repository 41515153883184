import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import { Link } from "react-router-dom";
import FooterDE from "../Symbols/FooterDE";
import ASN1 from "../../assets/AS_N_1.jpg";
import ASN6 from "../../assets/AS_N_6.jpg";
import AS_OV from "../../assets/AS_OV.png";
import AS1 from "../../assets/as_1.jpg";
import AS2 from "../../assets/as_2.jpg";
import AS3 from "../../assets/as_3.jpg";
import AS4 from "../../assets/as_4.jpg";
import AS5 from "../../assets/as_5.jpg";
import AS6 from "../../assets/as_6.jpg";
import AS2_1 from "../../assets/AS2_1.png";
import AS2_2 from "../../assets/AS2_2.png";
import AS2_3 from "../../assets/AS2_3.png";
import AS2_4 from "../../assets/AS2_4.jpg";
import { Helmet } from "react-helmet-async";
import "../../stylesheets/AboutUs.css";

class AutomotiveSolutions_New2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "PD",
      active_2: "S",
    };
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  changeCountry = (e, target) => {
    this.setState({
      active: target,
    });
  };

  changeCountry2 = (e, target) => {
    this.setState({
      active_2: target,
    });
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - Automotive Solutions</title>
          <meta
            name="description"
            content="Entdecken Sie hochwertige Steckverbinder und innovative
Verbindungslösungen von Pancon – präzise, zuverlässig und ideal für Automotive-
und Industrieanwendungen. Individuelle Steckverbinder für Ihre Anforderungen."
          />
          <meta property="og:title" content="Pancon - Automotive Solutions" />
          <meta
            property="og:description"
            content="Entdecken Sie hochwertige Steckverbinder und innovative
Verbindungslösungen von Pancon – präzise, zuverlässig und ideal für Automotive-
und Industrieanwendungen. Individuelle Steckverbinder für Ihre Anforderungen."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/automotive-solutions"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/automotive-solutions"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavDE />
        <div className="hero auto-sol">
          <div className="red-bg-hero"></div>
          <div className="hero-content new">
            <h1 className="hero-heading">Custom Solutions/Kundenspezifische Lösungen</h1>
            <h2 className="hero-heading-2">Präzision und Zuverlässigkeit
              für Ihre Branche</h2>
            <p className="hero-desc new">
              Willkommen bei Pancon, Ihrem Partner für innovative Verbindungslösungen. Mit jahrzehntelanger Erfahrung
              entwickeln wir maßgeschneiderte Steckverbinder und Komponenten, die höchsten
              Qualitätsstandards entsprechen und selbst in anspruchsvollsten Anwendungen
              überzeugen.
            </p>
            <button onClick={this.introScroll} className="default inverted">
              Mehr erfahren
            </button>
          </div>
        </div>
        <div ref={this.intro} id="intro" className="section first flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <h2 className="alternate-heading">Automotive Products</h2>
            </div>
            <p className="body padding-default no-marg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.            </p>
            <div className="all-thirds-container flex">
              <img className="img-full" src={AS_OV}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h dir-change">
          <div className="medium flex">
            <div className="heading1-cont flex center-v center-h">
              <h2 className="alternate-heading">Lösungen</h2>
            </div>
            <p className="body">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <div className="flex as-top">
              <div className="two-thirds marg">
                <h3 className="as">Coffeemachine</h3>
                <p className="body as">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="third marg flex center-h center-v">
                <img src={AS1}></img>
              </div>
            </div>
            <div className="flex as-top first">
              <div className="third marg flex center-h center-v">
                <img src={AS2}></img>
              </div>
              <div className="two-thirds marg">
                <h3 className="as">Waterpump</h3>
                <p className="body as">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds marg">
                <h3 className="as">Toilette</h3>
                <p className="body as">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="third marg flex center-h center-v">
                <img src={AS3}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="third marg flex center-h center-v">
                <img src={AS4}></img>
              </div>
              <div className="two-thirds">
                <h3 className="as">
                  E-Bike Motor
                </h3>
                <p className="body as">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds marg">
                <h3 className="as">
                  Fan
                </h3>
                <p className="body as">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="third marg flex center-h center-v">
                <img src={AS5}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="third marg flex center-h center-v">
                <img src={AS6}></img>
              </div>
              <div className="two-thirds marg">
                <h3 className="as">
                  Zapfsäule
                </h3>
                <p className="body as">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="less-bot section flex center-h">
          <div className="center-mob medium flex center-h">
            <div className="heading1-cont flex center-v center-h">
              <h2 className="alternate-heading">Was bieten wir an?</h2>
            </div>
            <div
              onClick={(event) => this.changeCountry(event, "PD")}
              className={
                this.state.active === "PD"
                  ? "dist-country as active"
                  : "dist-country as"
              }
            >
              Product Development
            </div>
            <div
              onClick={(event) => this.changeCountry(event, "PM")}
              className={
                this.state.active === "PM"
                  ? "dist-country as active"
                  : "dist-country as"
              }
            >
              Project Management
            </div>
            <div
              onClick={(event) => this.changeCountry(event, "P")}
              className={
                this.state.active === "P"
                  ? "dist-country as active"
                  : "dist-country as"
              }
            >
              Prototyping
            </div>
            <div
              onClick={(event) => this.changeCountry(event, "SP")}
              className={
                this.state.active === "SP"
                  ? "dist-country as active"
                  : "dist-country as"
              }
            >
              Seriell Production and Logistics
            </div>
          </div>
        </div>
        {this.state.active == "PD" ? (
          <div className="section flex center-h">
            <div className="medium flex">
              <div className="half">
                <p className="body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="half">
                <img className="img-full" src={AS2_1}></img>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.active == "PM" ? (
          <div className="section flex center-h">
            <div className="medium flex">
              <div className="half">
                <p className="body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="half">
                <img className="img-full" src={AS2_2}></img>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.active == "P" ? (
          <div className="section flex center-h">
            <div className="medium flex">
              <div className="half">
                <p className="body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="half">
                <img className="img-full" src={AS2_3}></img>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.active == "SP" ? (
          <div className="section flex center-h">
            <div className="medium flex">
              <div className="half">
                <p className="body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="half">
                <img className="img-full" src={AS2_4}></img>
              </div>
            </div>
          </div>
        ) : null}
        <div className="section full about-1 new-margin flex center-h center-v">
          <div className="half about-img-2"></div>
          <div className="half red-bg less">
            <h4>Lorem Ipsum</h4>
            <h2>Welche Technologien können wir?</h2>
            <p className="body inverted">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <ul className="body">
              <li>Moulding and overmoulding of connectors and ECU applications</li>
              <li>Assembly of connector applications</li>
              <li>Customer specific solutions</li>
              <li>Pressfit technology</li>
              <li>THR/SMD connectors</li>
              <li>IDC applications</li>
              <li>Crimp solutions</li>
              <li>High temperature plastic solutions</li>
              <li>Glow Wire solutions</li>
              <li>Jumper Switches</li>
              <li>LED/LCD</li>
            </ul>
          </div>
        </div>
        <div className="less-bot section flex center-h">
          <div className="center-mob medium flex center-h">
            <div className="heading1-cont flex center-v center-h">
              <h2 className="alternate-heading">Welche Produktionslösungen bieten wir an?</h2>
            </div>
            <div
              onClick={(event) => this.changeCountry2(event, "S")}
              className={
                this.state.active_2 === "S"
                  ? "dist-country as active"
                  : "dist-country as"
              }
            >
              Stamping
            </div>
            <div
              onClick={(event) => this.changeCountry2(event, "AA")}
              className={
                this.state.active_2 === "AA"
                  ? "dist-country as active"
                  : "dist-country as"
              }
            >
              Automation Assembling
            </div>
            <div
              onClick={(event) => this.changeCountry2(event, "M")}
              className={
                this.state.active_2 === "M"
                  ? "dist-country as active"
                  : "dist-country as"
              }
            >
              Molding
            </div>
            <div
              onClick={(event) => this.changeCountry2(event, "O")}
              className={
                this.state.active_2 === "O"
                  ? "dist-country as active"
                  : "dist-country as"
              }
            >
              Overmolding
            </div>
          </div>
        </div>
        {this.state.active_2 == "S" ? (
          <div className="section flex center-h">
            <div className="medium flex">
              <div>
                <p className="body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.active_2 == "AA" ? (
          <div className="section flex center-h">
            <div className="medium flex">
              <div>
                <p className="body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.active_2 == "M" ? (
          <div className="section flex center-h">
            <div className="medium flex">
              <div>
                <p className="body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.active_2 == "O" ? (
          <div className="section flex center-h">
            <div className="medium flex">
              <div>
                <p className="body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h">
              <p className="eye-catcher accent">Pancon - Verbindungstechnik für eine vernetzte Welt</p>
              <p className="body s-18">
                Industrie 4.0, Automatisierung und IoT stellen hohe Anforderungen an Verbindungssysteme. Unsere Lösungen bieten Unternehmen in verschiedensten Branchen die notwendige Performance, um diese Anforderungen zu erfüllen:
              </p>
            </div>
            <div className="half flex center-v center-h col">
              <Link className="default diff-1" to="/de/kontakt">
                <span>Kontaktieren Sie uns jetzt</span>
              </Link>
              <p className="body s-18">Wir freuen uns auf Ihre Anfrage!</p>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default AutomotiveSolutions_New2;
