import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import PO1 from "../../assets/po_1.png";
import PO2 from "../../assets/po_2.jpg";
import PO3 from "../../assets/po_3.jpg";
import PO4 from "../../assets/terminalblocks.png";
import "../../stylesheets/Kontakt.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import CRIMPDE from "../../assets/PAN_PRO_Kompetenzfolder_CRIMP-CON_D.pdf";
import BOARDDE from "../../assets/PAN_PRO_Kompetenzfolder_BOARD-CON_D.pdf";
import LATDE from "../../assets/PAN_PRO_Kompetenzfolder_LC127_DE_2024.pdf";

class Produktuebersicht extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [],
    }
    this.btn1 = React.createRef();
    this.btn2 = React.createRef();
    this.btn3 = React.createRef();
    this.state = {
      active: "",
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let count = 1;
    let records = [];
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/appiUuQajhQ6Drt6x/Docs?view=Sorted`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
    }
    this.setState({
      docs: records,
    });
  };

  changeView = (e, target) => {
    if (target === "1") {
      this.btn1.current.classList.add("active")
      this.btn2.current.classList.remove("active")
      this.btn3.current.classList.remove("active")
    } else if (target === "2") {
      this.btn1.current.classList.remove("active")
      this.btn2.current.classList.add("active")
      this.btn3.current.classList.remove("active")
    } else if (target === "3") {
      this.btn1.current.classList.remove("active")
      this.btn2.current.classList.remove("active")
      this.btn3.current.classList.add("active")
    }
    this.setState({
      active: target,
    });
  };

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>Pancon - Produktübersicht</title>
          <meta
            name="description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <meta property="og:title" content="Pancon - Kontakt" />
          <meta
            property="og:description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/produktuebersicht"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/product-overview"
            hrefLang="en"
          ></link>
        </Helmet>
        <NavDE />
        <div
          className="section flex first center-h less-bot"
        >
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h1>Produktübersicht</h1>
            </div>
            <div className="flex center-h center-v po-box">
              <div ref={this.btn1} onClick={(event) => this.changeView(event, "1")} className="third flex center-h center-v">
                <img className="img-pad" src={PO1}></img>
                <h3>Wire 2 Wire</h3>
              </div>
              <div ref={this.btn2} onClick={(event) => this.changeView(event, "2")} className="third flex center-h center-v">
                <img className="img-pad" src={PO2}></img>
                <h3>Wire 2 Board</h3>
              </div>
              <div ref={this.btn3} onClick={(event) => this.changeView(event, "3")} className="third flex center-h center-v">
                <img className="img-pad" src={PO3}></img>
                <h3>Board 2 Board</h3>
              </div>
{/*               <div ref={this.btn3} onClick={(event) => this.changeView(event, "3")} className="fourth flex center-h center-v">
                <img src={PO4}></img>
                <h3>Terminalblocks</h3>
              </div> */}
            </div>
            <div className="po-box-2">
              {this.state.active == "1" || this.state.active == "2" || this.state.active == "3" ? <h2>Produktgruppen</h2> : null}
              {this.state.active == "1" ? (
                <div className="flex">
                  <Link target={"_blank"} className="half" to="/de/produkte?series=MAS-CON">
                    <h3>MAS-CON</h3>
                    <p className="body">Im Bereich MAS-CON bietet Pancon Messerleisten und Federleisten samt Zubehör in zwei verschiedenen Größen an.<br></br>Die Serie 100 und die Serie 156. Bei den Bezeichnungen handelt es sich um den Abstand der Kontakte in einer Reihe.<br></br>Serie 100 hat einen Abstand von 0.100 Zoll {"(2,54 mm)"} und die Serie 156 hat einen Abstand von 0.156 Zoll (3,98 mm).<br></br>Beides sind übliche Rastermaße in der Elektroindustrie.</p>
                  </Link>
                  <Link target={"_blank"} className="half" to="/de/produkte?series=CRIMP-CON">
                    <h3>CRIMP-CON</h3>
                    <p className="body">Die Crimp-ConProduktlinie besteht aus unterschiedlichen Rastermaßen zur Erstellung von Wire 2 Wire und Wire2 Board Verbindungen.<br></br><br></br>1,00mm / 1,25mm / 1,27mm / 1,50mm / 2,00mm / 2,50mm / 2,54mm / 3,00mm / 3,96mm / 4,20mm / 5,08mm</p>
                    <a
                      target="_blank"
                      href={CRIMPDE}
                    >
                      <div className="flex marg-top">
                        <button className="default">Flyer</button>
                      </div>
                    </a>
                  </Link>
                  <Link target={"_blank"} className="half" to="/de/produkte?series=LAT-CON">
                    <h3>LAT-CON</h3>
                    <p className="body">Die LAT-CON Steckerfamilie ist für die Anwendung auf Leiterplatten in Verbindung mit Flachbandkabeln konzipiert. Pancon bietet sowohl Federleisten als auch Messerleisten in verschiedenen Baugrößen an:<br></br>Die Baureihe 033 mit 1,27mm und die Baureihen 050 und 057 mit 2,54mm Abstand von Kontakt zu Kontakt.</p>
                    <a
                      target="_blank"
                      href={LATDE}
                    >
                      <div className="flex marg-top">
                        <button className="default">Flyer</button>
                      </div>
                    </a>
                  </Link>
                </div>) : null}
              {this.state.active == "2" ? (
                <div className="flex">
                  <Link target={"_blank"} className="half" to="/de/produkte?series=MAS-CON">
                    <h3>MAS-CON</h3>
                    <p className="body">Im Bereich MAS-CON bietet Pancon Messerleisten und Federleisten samt Zubehör in zwei verschiedenen Größen an.<br></br>Die Serie 100 und die Serie 156. Bei den Bezeichnungen handelt es sich um den Abstand der Kontakte in einer Reihe.<br></br>Serie 100 hat einen Abstand von 0.100 Zoll {"(2,54 mm)"} und die Serie 156 hat einen Abstand von 0.156 Zoll (3,98 mm).<br></br>Beides sind übliche Rastermaße in der Elektroindustrie.</p>
                  </Link>
                  <Link target={"_blank"} className="half" to="/de/produkte?series=CRIMP-CON">
                    <h3>CRIMP-CON</h3>
                    <p className="body">Die Crimp-ConProduktlinie besteht aus unterschiedlichen Rastermaßen zur Erstellung von Wire 2 Wire und Wire2 Board Verbindungen.<br></br><br></br>1,00mm / 1,25mm / 1,27mm / 1,50mm / 2,00mm / 2,50mm / 2,54mm / 3,00mm / 3,96mm / 4,20mm / 5,08mm</p>
                    <a
                      target="_blank"
                      href={CRIMPDE}
                    >
                      <div className="flex marg-top">
                        <button className="default">Flyer</button>
                      </div>
                    </a>
                  </Link>
                  <Link target={"_blank"} className="half" to="/de/produkte?series=LAT-CON">
                    <h3>LAT-CON</h3>
                    <p className="body">Die LAT-CON Steckerfamilie ist für die Anwendung auf Leiterplatten in Verbindung mit Flachbandkabeln konzipiert. Pancon bietet sowohl Federleisten als auch Messerleisten in verschiedenen Baugrößen an:<br></br>Die Baureihe 033 mit 1,27mm und die Baureihen 050 und 057 mit 2,54mm Abstand von Kontakt zu Kontakt.</p>
                    <a
                      target="_blank"
                      href={LATDE}
                    >
                      <div className="flex marg-top">
                        <button className="default">Flyer</button>
                      </div>
                    </a>
                  </Link>
                  <Link target={"_blank"} className="half" to="/de/produkte?series=HI-CON">
                    <h3>HI-CON</h3>
                    <p className="body">Bei der HI-CON Produktlinie handelt sich um Steckverbinder gemäß den folgenden Standards:</p>
                    <ul><li>DIN EN 60 603-2</li><li>DIN 41612</li><li>IEC 60 603-2</li></ul>
                    <p className="body">Diese Steckverbinder werden hauptsächlich auf Leiterplatten verwendet.</p>
                  </Link>
                </div>) : null}
              {this.state.active == "3" ? (
                <div className="flex">
                  <Link target={"_blank"} className="half" to="/de/produkte?series=HI-CON">
                    <h3>HI-CON</h3>
                    <p className="body">Bei der HI-CON Produktlinie handelt sich um Steckverbinder gemäß den folgenden Standards:</p>
                    <ul><li>DIN EN 60 603-2</li><li>DIN 41612</li><li>IEC 60 603-2</li></ul>
                    <p className="body">Diese Steckverbinder werden hauptsächlich auf Leiterplatten verwendet.</p>
                  </Link>
                  <Link target={"_blank"} className="half" to="/de/produkte?series=Board-Con">
                    <h3>BOARD-CON</h3>
                    <p className="body">Die Board-Con Produktlinie umfasst Board 2 Board Stecksysteme (Messer- und Federleisten) in unterschiedlichen Bauformen und Rastermaßen:<br></br>0,8mm / 1,00mm / 1,27mm / 2,00mm /2,50mm / 2,54mm / 3,96mm / 4,20mm / 5,08mm</p>
                    <a
                      target="_blank"
                      href={BOARDDE}
                    >
                      <div className="flex marg-top">
                        <button className="default">Flyer</button>
                      </div>
                    </a>
                  </Link>
                </div>) : null}
              <div className="po-box-2">
                <h2>Sonstige Dokumente</h2>

                <div className="flex center-h sonstiges">
                  <a
                    target="_blank"
                    href={this.state.docs ? this.state.docs[6].fields.Document_DE[0].url : ""}
                  >
                    <div className="flex marg-top">
                      <button className="default">Pancon Broschüre</button>
                    </div>
                  </a>
                  <a
                    target="_blank"
                    href={this.state.docs ? this.state.docs[7].fields.Document_DE[0].url : ""}
                  >
                    <div className="flex marg-top">
                      <button className="default">Terminal Blocks</button>
                    </div>
                  </a>
                  <a
                    target="_blank"
                    href={this.state.docs ? this.state.docs[8].fields.Document_DE[0].url : ""}
                  >
                    <div className="flex marg-top">
                      <button className="default">Optimale Lagerfähigkeit</button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h">
              <p className="eye-catcher">Noch unsicher?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link className="default" to="/de/produkte">
                <span>Alle Produkte anzeigen</span>
              </Link>
              <Link className="default" to="/de/zubehoer-und-tools">
                <span>Zubehör und Tools</span>
              </Link>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Produktuebersicht;
